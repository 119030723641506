let clientName = "Wowi deals";
let productName = "Mocka";
let sloganValue = "35";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const fr = {

	/* Concerning the printing coupons pages */
	printCoupons: {
		page0:{
			title: "Comment voulez-vous recevoir vos coupons ?",
			explanation: "Vous pouvez les imprimer immédiatement, ou recevoir un e-mail contenant un lien qui vous permettra de les imprimer plus tard. Quel sera votre choix ?",
			optionDirectPrint: "Les imprimer directement",
			optionMailPrint: "Recevoir un e-mail",
		}, 
		page1:{
			title: "Impression de votre bon de réduction",
			intro: `Quelques recommandations pour que tout se passe bien :`,
			attention: `Vous ne pourrez imprimer vos coupons <b>qu'une seule fois</b>. Alors, avant d'imprimer, <b>préparez vos appareils&nbsp;!</b>.`,
			beforePrint: `Avant d'imprimer...`,
			step1: `Vérifiez que votre imprimante soit bien <b>connectée</b> à votre appareil (pc ou mobile).`,
			step2: `Vérifiez que votre imprimante soit <b>allumée et alimentée en papier</b>.`,
			step3: `Si tout est ok, cliquez sur le bouton "<b>imprimer</b>" pour imprimer vos eCoupons`,
			duringPrint: `Quand vous imprimez...`,
			attention2: `Choisissez les bons <b>paramètres d'impression</b>.`,
			warnScale: `Vérifier l'aperçu de vos eCoupons. Sont-ils visibles en entier ? Sinon, dans les options d’impression, vérifiez que le paramètre <b>&laquo;&nbsp;Mise à l'échelle&nbsp;&raquo;</b> (<i>"Scale"</i>) de votre imprimante soit réglé sur <b>&laquo;&nbsp;Ajuster à la zone imprimable&nbsp;&raquo;</b>  (<i>"Fit to printable area"</i>).`,
			badScale: `Si le paramètre &laquo;&nbsp;Mise à l’échelle&nbsp;&raquo; ("Scale") n’est pas correctement réglé, vos coupons pourraient ne pas être imprimés correctement et seraient <b>inutilisables</b>.`,
			waited: `Vous devriez voir le ou les eCoupons en entier dans l’aperçu.`,
			goodScale: `Assurez-vous que la mise à l’échelle soit réglée sur <b>&laquo;&nbsp;Ajuster à la zone imprimable&nbsp;&raquo;</b> ou équivalent.`,
			lastButNotLeast: `Si tous les points ci-dessus sont ok, il ne vous reste qu'à valider les <b>conditions générales</b> pour pouvoir imprimer vos eCoupons&nbsp;:`,
			conditions: "J’accepte les conditions générales relatives aux bons de réduction applicables à ce bon de réduction.",
			moreInfo: "Plus de détails",
			button: "Imprimer mon bon de réduction"
		}
	},

	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Cette action n'est pas encore disponible.",
		actionEnded: "Cette action est terminée.",
		maxReached: "Maximum de participations atteint",
		wrongCode: "Mauvais code",
		wrongCode2: "Lien de participation incorrect",
		alreadySend: "Participation déjà envoyée",
		registration: "Inscription",
		register: "S'inscrire",
		confirmReset: "Confirmation",
		confirmation: "Confirmation",
		
		confirmationCoupon: "Confirmation",
		confirmationMail: "Confirmation",

		choiceSurvey: "",
		survey: "Enquête",
		summary: "Récapitulatif",
		maintenance: "Maintenance",
		form1: "Demandez votre cashback maintenant\u00A0!",
		form2: "Encore quelques informations...", 
		wait: "Veuillez patienter...",
		instructions: "Comment participer",
		pageError: "Une erreur est survenue",

		iframe: "Imprimer mes coupons",
		modifLoginPass: "Modifier votre identifiant et/ou mot de passe.",
		modifProfile: "Modifier vos données de profil",
		modifPrefs: "Modifier vos préférences",
		profile: "Mon profil",
		rememberLogin: "Demander un rappel du login",
		resetPassword: "Demande de changement de mot de passe",
		changePassword: "Changer votre mot de passe",
		confirmCodeMailSent: "Code envoyé !",
		confirmLinkSent: "Lien envoyé !",
		warningBeforePrint: "Avant d'imprimer",
		consentAgain: "Changement dans les conditions générales",

		confirmReservation: "Réservation confirmée",
		summaryCashback: "Demander mes cashbacks",
		proofOfPurchasePage: "Envoyez vos cashbacks",
		confirmCashback: "Confirmation 👍",
		confirmPrime: "Confirmation 👍",

		cannotPrintCoupon: "Impossible d'imprimer",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Choisissez un mode d'inscription",
		thanksForRegistration: "Merci pour votre inscription !",
		aboutYou: "Dites-nous qui vous êtes",
		address: "Votre adresse",
		aboutProduct: "À propos de notre produit",
		ticketUpload: "Preuve d'achat",

		infoConnect: "Informations de connexion",
		userInfo: "Vos coordonnées",
		userPrefs: "Vos préférences",
	},

	/* inputsTexts is a list for every label, placeholder and helpers that Action API don't give. */
	inputsTexts: {
		listEan: {
			label: "Tapez ou sélectionnez le code à barres du produit dans la liste",
			placeholder: "Tapez ou sélectionnez",
			helper: "Le code à barre comporte 13 chiffres",
		},
		freeEan: {
			label: "Tapez le code à barres du produit",
			placeholder: "Tapez les 13 chiffres ici",
			helper: "Le code à barre comporte 13 chiffres",
		},
		dropdown : {
			placeholder: "Choisissez une réponse...",
			helper: " ",
			missingData: "Le champ doit contenir une réponse",
			noResult: "Pas de résultat",
			errorData: "La donnée est incorrecte",
			errorLength: "La donnée n'a pas la bonne longueur",
		},
		title: {
			label: "Titre",
			placeholder: "Comment pouvons-nous vous appeler ?",
			helper: " ",
		},
		gender: {
			label: "Genre",
			placeholder: "Comment vous définissez-vous ?",
			helper: " ",
		},
		firstName: {
			label: "Prénom",
			placeholder : "Mon prénom est...",
			helper: " ",
		},
		lastName: {
			label: "Nom",
			placeholder : "Mon nom est...",
			helper: " ",
		},
		email : {
			label: "E-mail",
			placeholder: "nom{'@'}exemple.be",
			helper: " "
		},
		emailbis : {
			label: "E-mail, à nouveau",
			placeholder: "nom{'@'}exemple.be",
			helper: " "
		},
		password: {
			label: "Mot de passe",
			placeholder: "",
			helper: ' '
		},
		passwordReset: {
			label: "Nouveau mot de passe",
			placeholder: "********",
			helper: ' '
		},
		passwordRegister : {
			label: "Mot de passe",
			placeholder: "",
			helper: `Le mot de passe doit faire entre 8 et 20 caractères.<br>
			Il doit contenir au moins un chiffre, une majuscule et une minuscule.<br>
			Et au moins un de ces caractères&nbsp;:<br> &#33; &#35; &#36; &#37; &amp; &#42; &#43; &#45; &#47; &#61; &#63; &#94; &#95; &#46; &#123; &#124; &#125; &#126; &#64; &#92; &#91; &#93;.`
		},
		wowiCardFilter: {
			label: "Filter selon :",
			placeholder: "",
			helper: " ",
		},
		phone : {
			label: "Mobile",
			placeholder: "Ex : 0477123456",
			helper: " ",
		},
		IBAN : {
			label: "IBAN",
			placeholder: "ex : BE00123000456000",
			helper: "Le numéro doit commencer par le code pays (BE/LU/...)"
		},
		VATNumber: {
			label: "Numéro de TVA",
			placeholder: "Ex: BE0123123123",
			helper: "Le numéro doit commencer par le code pays (BE/LU/...)"
		},
		googleAPI:{
			label: "Merci de rechercher votre adresse complète (avec le numéro de maison) afin de pouvoir valider le formulaire.",
			placeholder: "Rechercher...",
		},
		street : {
			label: "Rue",
			labelfacture: "Adresse de facturation",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Numéro",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Boite",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Code postal - Localité",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Localité",
			placeholder: "",
			helper: " "
		},
		cityPrecision: {
			label: "Veuillez préciser",
			placeholder: "",
			helper: " "
		},
		countries: {
			label: "Pays",
			placeholder: "Choisissez un pays",
			helper: " "
		},
		conditions : {
			part1 : "J'accepte les",
			link: "conditions générales",
			part3: " "
		},
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Quel produit avez-vous acheté ?",
			placeholder: "Choisissez une réponse...",
			helper: " "
		},
		retailer: {
			label: "Où avez-vous acheté le produit ?",
			placeholder: "Choisissez une réponse...",
			helper: " "
		},
		freeRetailer: {
			label: `Dans quel magasin avez-vous acheté vos produits ${productName} ?`,
			placeholder: "...",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Veuillez remplir ce champ",
			error_wrongValue: "La valeur est incorrecte",
			error_passwordLength: "Le mot de passe doit avoir entre 8 et 20 caractères. ",
			error_passwordLowercase: "Le mot de passe doit contenir au moins une minuscule. ",
			error_passwordUppercase: "Le mot de passe doit contenir au moins une majuscule. ",
			error_passwordNumber: "Le mot de passe doit contenir au moins un chiffre. ",
			error_passwordSpecialCha: "Le mot de passe doit contenir au moins un caractère spécial",
		},
		datePurchase : {
			label: `Quand avez-vous acheté vos produits ${productName} ?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>ATTENTION :</strong> Veuillez utiliser le calendrier pour introduire la date.`,
		},
		uploads: {
			ticket: {
				label: "Cliquez ou déposez votre ticket ici."
			},
			dropzone1: {
				label: "Cliquez ou déposez une photo du code barre ici.",
			},
		},
		optin : {
			part1:"Je souhaite être tenu au courant des promotions ",
			linklabel:`${clientName}`,
			link:"https://www.CLIENT.be/fr/",
			part2:"",
			label : "", /* Should not be visible */
			helper : "",
			complement: `J'accepte de recevoir des e-mails en provenance de ${clientName}.`,	
			longtext : {
				part1: `Oui, je souhaite recevoir par e-mail des informations sur les promotions, actualités et offres spéciales des marques participantes. Je suis informé(e) que je peux gérer ou retirer mon consentement à tout moment en écrivant à l'adresse communiquée dans les `,
				linkCaption: `conditions générales`,
				part2: ` ou dans chaque e-mail. Pour plus de détails sur la manière dont vos données sont traitées, veuillez consulter la Déclaration de Confidentialité des marques participantes.`,	
			}, 
		},
		code: {
			label: "Veuillez taper ici le code reçu", 
			placeholder: "Exemple: 123456",
			helper: "Il contient 6 chiffres.",
		}
	},

	wowicard: {
		reimbursed: "remboursé",
		textOffersFirstPart: "Encore",
		textOffersLastPart: {
			plural: "offres disponibles",
			singular: "offre disponible",
		},
		textDateFirstPart: "Il reste seulement",
		textDateLastPart: {
			plural: "jours",
			singular: "jour",
		},

		claimed: "Offre déjà reçue",
		soldout: "Offre épuisée",
		expired: "Offre périmée",
		reserved: "Offre réservée",
		soonOut: "Offre bientôt épuisée",
		until: "Jusqu'au",
	},

	wowiFilterList: {
	default: "Initial",
		valueAsc: "Valeur de l'offre croissante",
		valueDesc: "Valeur de l'offre décroissante",
		soonClosed: "Bientôt terminées",
		random: "Ordre aléatoire",
		selected: "Sélectionnées",
	},


	testPanel: {
		title: 'Les pages "cachées"',
		text: "Cliquez sur les boutons ci-dessous pour accéder aux pages du formulaire qui ne font pas partie du flux normal de la participation",
		pages: {
			before: "Avant le début",
			after: "Après la fin",
			maxparticip: "Max. participation",
			wrongcode: "Mauvais code",
			alreadysent: "Déjà envoyé",
			maintenance: "En maintenance",
			loading: "Chargement...",
		},
		hide: "Cachez-moi",
		show: "Montrez-moi",
	},



	/* 
	ABOVE: WHAT MUST BE USED NOW
	BELOW: WHAT WAS USED BEFORE 
	*/

	page404: {
		title: "404",
		text: "Cette page n'existe pas.",
		back: "Retour au formulaire",
	},

	errors: {
		noCouponFile: {
			intro: "Mais pas de panique :",
			help: "Nous allons regénérer le coupon et vous recevrez à nouveau un e-mail quand il sera prêt.",
		},
		e404: "Erreur 404",
		error: "Erreur",
		wrongUserKey: "Mauvaise clé d'identification",
		userDoNotExist: "L'utilisateur n'a pas été trouvé.",
		noCouponsToOrder: "Il n'y a aucun coupon qui puisse être demandé.",
		noSelectedProducts: {
			title: "Oups !",
			message: "Vous devez d'abord sélectionner des coupons à imprimer.",
		},
		accesBlocked: "Votre accès à Wowi deals est bloqué.",
		// fields errors
		emptyField: "Vous devez donner une réponse",
		dateInvalid: "La date n'est pas valide",
		dateBefore: "La date ne peut être une date future",
		dateOutOfPeriod: "L'achat doit se faire dans les dates valides pour cette action",
		wrongcnk : "Le code CNK n'est pas correct",
		fieldsMissing: "Impossible d'envoyer la participation car un ou plusieurs champs ne sont pas remplis/valides",
		fieldsMissingTitle: "Champs vides/invalides",
		unmanaged: "Une erreur imprévue est survenue.",
		// modale errors, for the user
		fieldMissingValue: "Un ou plusieurs champs ont une valeur erronée ou vide.",
		fieldMissingValueCsq: "Veuillez compléter le formulaire.",
		noDescription: "Aucune description disponible.",
		undefinedError: "Erreur indéfinie.",
		undefinedErrorPleaseRetry: "Une erreur indéfinie est survenue. Veuillez réessayer.",
		maintenanceInProgress: "Maintenance en cours.",
		formUnavailable: "Veuillez réessayer plus tard.",
		error500: "Une erreur avec le serveur est survenue.",
		oupsy: "Oups !",
		// Erreurs we don't need to give user too much details on, but a ref! So the user can tell us!
		// KISS Code explanation: take the first letter of each word of the variable
		countryListUnattainable: "Code: CLU",
		noFieldConfigFound: "Code: NFCF",
		participationNotSent: "Code: PNS",
		partOwnerNotCreated: "Code: PONC",
		leadNotFound: "Code: LNF",
		retailerListNotFound: "Code: RLNF",
		productCodesNotFound: "Code: PCNF",
		localizationDataNotFound: "Code: LDNF",
		codeWrongOrUnidentified: "Code: CWOU",
		mailWithCouponsNotSent: "Code: MWCNS",
		printCouponsNotWorking: "Code: PCNW",
	},

	maintenance: {
		line1 : "Ce site web est actuellement en maintenance et n'est pas accessible. Veuillez réessayez plus tard.",
		line2 : "Notre équipe fait tout pour que le site soit de nouveau accessible le plus rapidement possible.",
		thanks : "Merci de votre compréhension.",
	},

	/* Classic Footer */
	footer: {
		genterms: "Conditions générales",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "",
			label: "Déclaration de confidentialité",
		},
		cookies: {
			link: "",
			label: "Politique en matière de cookies",
		}
	},

	gdpr: {
		title: "Avis de confidentialité",
		client: `${clientName}`,
	},

	navigation: {
		doSurvey: "Répondre à l'enquête",
		skipSurvey: "Demander le cashback",
		previous: "Page précédente",
		next: "Page suivante",
		claimCashback: "Demander mon cashback",
		signup: "M'inscrire !",
		participate: "Participer !",
		toCustomerWebsite: {
			label: "Vers notre site",
			link: ""
		},
		readMoreOnWebsite: "En savoir plus",

		menu: {
			coupons: "Les e-coupons",
			cashbacks: "Les cashbacks",
			primes: "Les codes promo",
			myProfil: "Mon profil",
			logout: "Se déconnecter", 
		}

	},

	breadcrumbSteps: {
		info: "Comment Participer",
		registration: "Inscrivez-vous",
		store: "Faites vos achats",
		form: "Remplissez le formulaire",
		survey: "Répondez à un questionnaire optionnel",
		cashback: "Recevez votre remboursement",
		summary: "Récapitulatif"
	},

	formHeader: {
		bottomText: `* à la conditions...`,
		macaronText: `remboursés&nbsp;!`,
		mainTitle: `Inscrivez-vous et obtenez votre cashback&nbsp;!`
	},

	buttons: {
		validation: "Valider",
		cancel: "Annuler",
		changePassword: "Modifier",
		logme: "Me connecter",
		sendLink: "M'envoyer le lien",
		login: "Je me connecte",
		logout: "Se déconnecter", 
		register: "Je m'inscris",
		printMyCoupons:"Imprimer mes coupons maintenant",
		emailMyCoupons:"Recevoir mes coupons",
		emailMyPromocode: "Recevoir mon code promo",
		updateLoginInfo:"Modifier",
		modifyProfile:"Modifier mes coordonnées",	
		updatePrefs: "Modifier les préférences",
		updateProfile: "Mettre à jour mon profil",
		backToOffers:"Retourner à l'accueil",
		backToProfile:"Retourner au profil",
		myProfil: "Mon profil",
		goToOffers: "Aller à l'accueil",
		enjoyCoupons: "Profiter des eCoupons",
		enjoyCashback: "Profiter des cashbacks",
		enjoyPromocodes: "Profiter des codes promo",
		sendCashbacks: "Demander mes cashbacks",
		reserveCashbacks: "Réserver les cashbacks",
	},

	pageLitteracy: {

		probanner: {
			title1: "Vous êtes professionel et vous disposez d'un numéro de TVA ?",
			title2: "Ceci vous intéressera certainement	:",
		},

		cannotPrintCoupon: {
			text: "Malheureusement, l'impression depuis votre device n'est pas encore gérée par Wowi deals.",
			text2: "Veuillez essayer depuis un autre navigateur (Firefox, Safari), ou depuis un PC/MAC.",
		},

		header: {
			spareCoupons: `Nos offres eCoupons`,
			spareCashbacks: `Nos offres cashbacks`,
			sparePrimes: `Nos offres codes promo`,
		},

		newConsent: {
			consetGenCond: `Avis&nbsp;: les conditions générales ont été modifiées depuis la dernière action Wowi deals. Pour poursuivre, veuillez accepter les nouvelles conditions. Vous ne devrez le faire qu'une seule fois.`,
			consetOptin: `Vous pouvez également donner votre consentement pour recevoir les communications des nouvelles marques participantes&nbsp;:`,
		},

		notYetRegistered: "Pas encore inscrit ?",
		noMoreOffers: "Il n’y a plus d’offre disponible en ce moment.",
		infoModifyLimitation: "Vous ne pouvez modifier qu'une donnée à la fois.",
		profileGreeting: "Bonjour",
		profileIntro : "Vous nous avez communiqué les informations suivantes. Depuis cette page, vous pouvez les modifier.",
		profileLogin: "Identifiant ", 
		profileEmail: "E-mail ",
		profileTel: "Tél. ",
		profilePassword: "Password ",
		profileFirstName: "Prénom ",
		profileLastName: "Nom ",
		profilePostalCode: "Code postal ",
		profileCity: "City ", 
		profileCountry: "Pays ",
		warningBeforePrint: `Il n'est possible de demander l'impression de vos coupons qu'une seule fois. Assurez-vous que votre appareil est bien connecté à une imprimante et que celle-ci est prête à imprimer.`,
		warningAlternative: "Si vous n'êtes pas en mesure d'imprimer immédiatement vos coupons, recevez un lien dans un e-mail qui vous permettra de les imprimer plus tard.",
		aboutWowi: `Épargnez plus de <strong>${sloganValue} €</strong>&nbsp;!`,

		wowiLoggedGreeting: `Sélectionnez les offres dont vous voulez bénéficier&nbsp;:`,
		wowiLoggedActionEnded: `Cette action est terminée. Les offres ne sont plus disponibles.`,
		wowiIntro: `Découvrez des réductions exclusives.<br>	Profitez de la facilité d’accès via notre plateforme.<br>Accéder à des offres exclusives et personnalisées.`,
		modifProfile: "Modifiez ci-dessous les valeurs que vous voulez mettre à jour.",
		smsToConfirm: "Un code de confirmation vous sera envoyé par SMS ou par e-mail (si vous modifiez le numéro de téléphone) pour valider le changement de ces données.",
		pastOffers: "Offres plus disponibles",
		printMyCoupon: "Imprimer mes coupons",
		sendProfile: "S'inscrire",

		modales : {
			login: "Connexion",
			code: "Code",
			askCode: "M'envoyer un nouveau code",
			codeError: `Le code que vous avez tapé n'est pas reconnu.<br>Veuillez réessayer.`,
			validationCode: "Code de validation",
			forgotLogin: "J'ai oublié mon identifiant.",
			forgotPassword: "J'ai oublié mon mot de passe.",
			errorLoginPassUnrecognised: `Votre identifiant et/ou votre mot de passe n'ont pas été reconnus. Veuillez réessayer.`,
			errorCodeUnrecognised: `Le code que vous avez indiqué n'a pas été reconnu.<br>Veuillez réessayer ou demander un nouveau code.`,
			shouldReceiveSMS: "Vous devriez recevoir d'ici peu de temps un code de 6 chiffres par SMS ou par mail. Veuillez saisir ce code ici pour confirmer votre identité.",
			
			reservation: {
				titleModaleBox: "Réservation",
				titleInsideModale: "Qu'est-ce que c'est ?",
				p1: "Un cashback vous donne droit à un remboursement (total ou partiel) du prix d’achat de votre produit lorsque vous l’avez acheté en magasin. Comme nos promotions cashback sont limitées en stock, nous vous donnons 7 jours pour réserver votre produit. Vous avez ainsi le temps de vous rendre dans le magasin, d'acheter le produit et de soumettre votre demande de remboursement dans le délai de 7 jours. De cette façon, vous pouvez être sûr de bénéficier de la promotion sur votre produit préféré.",
				p2: "Si vous ne le faites pas dans les 7 jours, cette offre sera à nouveau disponible pour tout le monde.",
				button: "J'ai compris",
			},
			emptyOrder: {
				title: "Annulation",
				text: "Le ou les cashbacks sélectionnés n'ont pas pu être réservé ou commandés, parce qu'ils sont déjà réservés ou commandés ! 😃",
				button: "Ok"
			},
			participation: {
				titleModaleBox: "Participation",
				titleInsideModale: "Comment faire ?",
				p1: `Pour obtenir votre cashback, vous devez, via le formulaire de participation&nbsp;:`,
				li1: `Nous communiquer votre compte bancaire IBAN s’il n’est pas déjà renseigné dans votre profil.`,
				li2: `Selon les produits pour lesquels vous demandez un remboursement, sélectionner le code à barres dans la liste ou remplir le champ avec le codes à barres.`,
				li3: `Nous transmettre une photo ou un scan de votre ticket de caisse.`,
				p2: `Veillez à entourer sur le ticket de caisse <b>la date d’achat</b> ainsi que <b>les produits</b> pour lesquels vous demandez un remboursement.`,
				p3: `Ensuite, rien de plus simple : cliquez sur « Demander vos cashbacks », remplissez le formulaire et soumettez votre demande.`,
				p4: `Si vous avez plusieurs tickets de caisse, vous devrez les envoyer un par un&nbsp;: sélectionnez les produits présents sur un ticket, participez, puis sélectionnez les produits du ticket suivant et participez à nouveau, etc.`,
				p5: `Vous pouvez demander un cashback quand vous voulez durant toute la durée de l’action.`,
				button: "J'ai compris",
			},
		},
		resetPassword: "Veuillez indiquer l'adresse e-mail avec laquelle vous vous êtes inscrit. Si votre adresse est connue, vous y recevrez un lien qui vous permettra de changer votre mot de passe.",
		changePassword: "Indiquez ici le nouveau mot de passe que vous souhaitez utiliser. Un code de confirmation vous sera ensuite envoyé par SMS pour confirmer le changement.",
		confirmLinkSent: "Si l'adresse e-mail communiquée est connue, un e-mail vous a été envoyé contenant un lien qui vous permettra de modifier votre mot de passe.",
		confirmReset: "Votre mot de passe a bien été modifié. Vous pouvez dès à présent l'utiliser pour vous connecter.",
		endOfList: "Fn de la liste.",
		mustBeLogged: "Vous devez être connecté pour pouvoir profiter des offres.",

		confirmReservation: {
			title: `Vous avez réservé avec succès les produits suivants&nbsp;:`,
			p1 : "Les réservations couvrent une période de 7 jours pendant lesquels nous vous garantissons la possibilité de bénéficier du ou des cashback(s) réservé(s).",
			p2 : "Passé ce délai de 7 jours, nous ne pouvons plus vous garantir de pouvoir bénéficier de nos offres de cashback en nombre limité. Ne trainez donc pas ! 🙂",
			btn_back: "Retour aux offres",
		},

		summaryCashback: {
			p1: `Vous ne pouvez soumettre <b>qu’un ticket de caisse à la fois</b>.`,
			p2: `Si vos achats sont tous présents sur le même ticket de caisse, sélectionnez toutes les offres qui correspondent.<br>`,
			p3: `Si vous avez effectués vos achats dans des enseignes différentes ou à des moments différents, et que vous avez plusieurs tickets de caisses, sélectionnez les offres de tous les produits présents sur un seul ticket. Demandez ces offres, puis faites de même pour les autres tickets de caisse.`,
			title: `Vous avez sélectionné pour ce ticket de caisse les produits suivants&nbsp;:`,
			btnModify: "Modifier ma sélection",
			btnValidate: "Valider ma sélection",
		},
		proofOfPurchase: {
			title: "Veuillez vérifier que votre compte bancaire IBAN correct est mentionné dans le champ ci-dessous.",
			title2: "Sélectionnez dans la liste ou remplissez le champ avec les codes à barres des produits pour lesquels vous demandez un remboursement.",
			title3: "Votre preuve d'achat",
			p1: "Sur votre preuve d'achat, veuillez entourer les produits, ainsi que la date d'achat. Photographiez ou scannez ensuite votre preuve d'achat et déposez-là dans le champ ci-dessous.",
			btn_send: "Envoyer",
		},
		confirmCashback: {
			p1: "Nous vous remercions de votre participation.",
			p2: "Si votre demande est conforme à nos conditions générales, vous recevrez le remboursement relatif aux produits sélectionnés dans un délai de 2 à 4 semaines.",
			p3: "Par précaution, conservez bien vos preuves d’achat jusqu’à ce que vous ayez reçu votre remboursement.",
			p4: "Entre-temps, vous pouvez profiter également de",
			or: "et de",
			linktocoupons: "nos offres d’eCoupons",
			linktocashbacks: "nos offres de Cashback",
			linktoprimes: "nos codes promo",
			p4final: "",
			p5: "Bien à vous,",
			p6: "L’équipe Wowi deals",
			btn_again: "Demander d'autres cashbacks",
			btn_back: "Revenir à la liste des offres",
		},
		confirmPrime: {
			p1: "Nous vous remercions de votre participation.",
			p2: "Nous vous avons envoyé par e-mail votre/vos code(s) unique(s).",
			warn: `Attention, si vous n'avez pas reçu d'e-mail d'ici quelques minutes, pensez à consulter vos <b>spams&nbsp;/&nbsp;courriers indésirables</b>.`,
			p3: "Rendez-vous sur le site de nos clients pour profiter de ces codes.",
			p4: "Entre-temps, vous pouvez profiter également de",
			or: "ou de",
			linktocoupons: "nos offres d’eCoupons",
			linktocashbacks: "nos offres de Cashback",
			linktoprimes: "nos codes promo",
			p5: "Bien à vous,",
			p6: "L’équipe Wowi deals",
			btn_again: "Demander d'autres codes promo",
			btn_back: "Revenir à la liste des offres",
		},

		wowiCashbacks: {
			register: "Inscrivez-vous",
			registerFor: ` pour profiter d'offres sur de nombreux produits&nbsp;!`,
			action: `Sélectionnez les cashbacks que vous voulez <strong>réserver</strong> ou <strong>profitez-en</strong> immédiatement.`,
			infoReserve: `<b>Réservez</b> votre participation à un cashback pendant une durée de 7 jours afin d’être certain(e) de pouvoir en bénéficier.`,
			infoAsk: `<b>Demandez</b> votre cashback dès que vous avez fait vos achats. Pensez à avoir votre ticket de caisse à portée de main.`,
			moreInfo: "Plus d'info",
		},

		pageError: {
			intro: "Une erreur est survenue qui empêche le formulaire de fonctionner.",
			solution1 : "Veuillez essayer de recharger le formulaire ou réessayer plus tard.",
			solution2 : `Si l'erreur persiste, prenez contact avec Wowi deals sur l'e-mail <a href="mailto:infoligne{'@'}wowideals.be">infoligne{'@'}wowideals.be</a>.`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Page d'information",
				register: "Si vous comptez participer plus tard, inscrivez-vous maintenant, ainsi, vous recevrez un rappel.",
				store: {
					line1a: "Rendez-vous en magasin entre le",
					line1b: "et le",
					line2: "Achetez un produit MARQUEMACHIN.",
					line3: "Conservez bien votre ticket de caisse / facture.",
				},
				form: {
					line1: "Introduisez vos coordonnées complètes.",
					line2: "Sur la preuve d’achat de vos produits MARQUEMACHIN (facture / ticket de caisse), entourez la date, les produits achetés et le prix.",
					line3: "Photographiez ou scannez ensuite votre preuve d’achat dans son entièreté.",
					line4: "Téléchargez cette photo ou ce scan.",
				},
				survey: "Répondez à une enquête optionnelle et recevez un coupon de réduction.",
				cashback: "C’est terminé. Après validation, vous recevrez votre remboursement dans un délai de 6 semaines.",
			}
		},
		maxReached : "Cette action est terminée car elle a atteint son maximum de participations.",
		wrongCode: {
			text: `La clé fournie est erronée. Vous devez utiliser le lien que vous a envoyé ${clientName}.`,
			textregistration: "Mais peut-être souhaitez-vous vous inscrire à l'action ?",
			errorWithReparticipation: `Une erreur est survenue avec votre lien de participation.<br>Veuillez contacter nos équipes techniques en envoyant un e-mail à <a href="mailto:infoligne{'@'}wowideals.be">infoligne{'@'}wowideals.be</a> en joignant les informations ci-dessous&nbsp;:`
		},
		alreadySend: "Vous avez déjà envoyé votre participation.",
		registration : {
			byRegistering: "En m'inscrivant à cette action, j'accepte ",
			privacyNotice: "la politique en matière de vie privée",
			claimMail : "S'inscrire par e-mail",
			claimWhatsapp: "S'inscrire par Whatsapp",
			claimSms: "S'inscrire par SMS",
			or: "Ou",
			whatsappTitle: "Cliquez sur le bouton Whatsapp",
			whatsappInfo: {
				part1: `Une fenêtre s'ouvrira, vous serez ensuite invité à envoyer le mot`,
				part2: `depuis votre Whatsapp.`
			},
		},
		confirmRegistration: {
			smsLine1 :"Vous allez très vite recevoir un SMS avec tous les détails.",
			smsLine2: "Aller voir !",
			whatsappLine1: "Vérifiez votre téléphone",
			whatsappLine2: "Toutes les informations ont été envoyées par Whatsapp",
			mailLine1: "Vous allez très vite recevoir un e-mail avec tous les détails.",
			mailLine2: "Découvrez cet e-mail !",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Répondez encore à une enquête et obtenez un e-coupon de 1€ valable sur votre prochain achat",
		},
		survey: {
			title: "Merci d'encore répondre à ces quelques questions",
			about: "Enquête",
		},
		confirmation: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions pour votre participation à Wowi deals organisé par HighCo DATA.`,
				p2: `Nous vous avons envoyé un e-mail contenant le lien pour imprimer vos e-coupons.`,
				warn: `Attention, si vous n'avez pas reçu d'e-mail d'ici quelques minutes, pensez à consulter vos <b>spams&nbsp;/&nbsp;courriers indésirables</b>.`,
				p3: `Profitez-en et n'hésitez pas à partager les avantages des offres Wowi deals avec vos amis et votre famille&nbsp;!`,
				signature: `L'équipe ${clientName}`
			},
		},
		confirmationCoupon: {
			title: "Vos coupons ont-ils bien été imprimés ?",
			btn_yes: "Oui",
			btn_no: "Non",
		},
		goodCouponPrinting: {
			title: "Confirmation",
			textes: {
				p1: `Nous vous remercions d'avoir imprimé vos eCoupons.`,
				p2: "Foncez dans votre magasin le plus proche afin de les échanger.",
				p3: "",
				p4: `L'équipe <em>${clientName}</em>`
			},
			btn_back: "Revenir à la liste des offres",
		},
		badCouponPrinting: {
			title: "Vos coupons ne sont pas correctement imprimés",
			textes: {
				p1: `Nous sommes désolés de ce contre-temps.`,
				p2: `Nos équipes ont été prévenues.`,
				p3: `Vous recevrez bientôt un <b>e-mail</b> avec un nouveau lien pour faire une <b>nouvelle impression</b>.`,
				p4: `L'équipe <em>${clientName}</em>`
			},
			btn_back: "Revenir à la liste des offres",
		},


		confirmationMail: {
			title: "L'e-mail a bien été envoyé",
			textes: {
				p1: `L'e-mail a bien été envoyé. Vous devriez le recevoir bientôt. Si ce n'est pas rapidement le cas, pensez à vérifier s'il n'a pas été placé dans les courriers indésirables.`, 
			}
		},
		actionEnded: {
			text: "N'hésitez pas à visiter régulièrement notre site pour vous tenir informé des actions futures.",
			title2: "Vous aviez des cashbacks réservés ?",
			text2: "Vous pouvez encore soumettre vos tickets durant toute la durée de réservation de vos cashbacks.",
			btn_log: "Je me connecte",
			btn_offers: "Voir les offres restantes",
		},
		beforeStart: {
			text: "Vous pourrez y accéder à partir du ",
			shouldBeginSoon: "L'action devrait bientôt commencer. Veuillez revenir plus tard."
		},
	},





	inputLabel: {
		defaultListHelper: "Choisissez une proposition dans la liste",
		mandatoryFields: "Champs obligatoires",
		noResult: "Pas de résultat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: "Quel est le code CNK du produit Clearblue acheté ?",
			placeholder: "0000-000",
			helper: "Le format est : XXXX-XXX",
			error: "Le code CNK n'est pas correct",
		},



        // https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation



/*
        typePassword : "Indiquez votre mot de passe",
        helpPassword : "Votre mot de passe doit contenir X, Y et Z",
        typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
        */


		upload: {
			state: "statut",
			uploadYourFile: "Envoyer vos fichiers",
			clickOrDrop: "Cliquez ou déposez votre ticket ici.",
			rules: "L'image doit être un fichier de type : JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Retirer",
			waiting: "En attente de l'envoi...",
			uploaded: "Envoyé !",
			error: "Echoué",
			uploadsDone: "Upload réussi",
			onlyImages: "Seulement des images sont acceptées.",
			maxSize: "Taille maximum de 20 Mo.",
			uploadFile: "Télécharger le fichier",
			generalError: "Erreur, veuillez ressayer plus tard"
		}
	},
}
