<template>
	<div>

		<!-- mask to close field if user click outside the field -->
		<div 
		class="inputmask" 
		v-if="isOptionsListOpen"
		@click="closeWithoutSelection"
		></div>

		<div class="selectfield field">

			<label :for="translationSource"><span v-html="$t(`inputsTexts.${translationSource}.label`)"></span><span v-if="isRequired" class="req">&nbsp;*</span></label>

			<div class="dropdown" :class="{'open': isOptionsListOpen}">

				<input 
				type="text"
				:name="name"
				:autocomplete="autocompleteName"
				:id="translationSource"
				v-model="textInField"
				@input="userChangedInput"
				:placeholder="$t(`inputsTexts.${translationSource}.placeholder`)"
				:class="{'hasSuccess': isInSuccess, 'hasError': isInError, 'isLoading': isLoading}"
				@keyup.prevent.down="keyPressedDown"
				@keyup.prevent.up = "keyPressedUp"
				@keyup.prevent.enter = "keyPressedEnter"
				:disabled="isDisabled"
				autocapitalize="off" 
				spellcheck="false" 
				autocorrect="off"
				>


			<!-- Toggler -->
			<div class="toggle" @click="toggleListOpeness()">
				<span class="arrow-up" v-show="isOptionsListOpen">
					<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
					viewBox="0 0 36 36" xml:space="preserve">
					<g>
						<!-- <polygon points="0,24 18,6 36,24 36,30 18,12 0,30"/> -->
						<polygon points="0,24 18,6 36,24 31,28 18,15 5,28"/>
					</g>
				</svg>
			</span>
			<span class="arrow-down" v-show="!isOptionsListOpen">
				<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
				viewBox="0 0 36 36" xml:space="preserve">
				<g>
					<!-- <polygon  points="0,6 18,24 36,6 36,12 18,30 0,12"/> -->
					<polygon  points="5,8 18,21 31,8 36,12 18,30 0,12"/>
				</g>
			</svg>
		</span>
	</div>






	<!-- Options list -->
	<ul
	v-show="isOptionsListOpen"
	class="options-list">
	<li 
	v-for="(option, index) in optionsList"
	:key="index"
	@click="selectThisOption(option, index)"
	:class="{'active': currentActiveOption === index}"
	>{{option.description}}</li>
	<li v-show="noResult" @click="closeWithoutSelection">{{$t('inputsTexts.dropdown.noResult')}}</li>
</ul>

</div>
</div>

<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
<div class="helper"><span v-html="$t(`inputsTexts.${translationSource}.helper`)"></span></div>

</div>
</template>

<!-- ================================================================================== -->

<script>
	import FormFields from "../mixins/FormFields";
	import customLog from "../mixins/CustomLog";

	export default {
		name:"SelectApi",
		mixins: [
			customLog,
			FormFields,
			],
		props: {
			isLeadRequested: {
				type: Boolean,
				required: false,
			default: true,
			},
			isArrowVisible: {
				type: Boolean,
				required: false,
			default: true
			},
			dataSource: {
				type: String,
				required: true,
			},
			dataRange: {
				type: Object,
				required: false,
			},
			caractersBeforeOpenList : {
				type: Number,
				required: false,
			default: 3
			},
			autocomplete: {
				type: Boolean,
				required: true,
			},
			isDisabled: {
				type: Boolean,
				required: false,
			default: false,
			}
		},

		data(){
			return {
				isOptionsListOpen: false,
				optionsList: [],
				optionsFullList: [],
				displayedInTheField: null,
				currentActiveOption: null,
				noResult: false,
			}
		},

		computed: {
			textInField: {
				get(){
					return this.displayedInTheField;
				},
				set(newVal){
					this.displayedInTheField = newVal;
				}
			}
		},

		methods: {

			keyPressedDown(){
				if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length -1){
					this.downOneItem();
				}
				if(!this.isOptionsListOpen){
					this.makeNewOptionsList(false);
					this.isOptionsListOpen = true;
				}
			},

			keyPressedUp(){
				if(this.isOptionsListOpen && this.currentActiveOption > 0){
					this.upOneItem();
				}else{
					this.closeOptionsList();
					this.currentActiveOption = null;
				}
			},

			keyPressedEnter(){
				if(this.isOptionsListOpen){
					this.log("Enter when list is open", 'low');
					this.selectThisOption(this.optionsList[this.currentActiveOption], this.currentActiveOption);
				}
			},

			upOneItem(){
				this.currentActiveOption -= 1;
			},

			downOneItem(){
				this.currentActiveOption += 1;
			},

			selectThisOption(option, index){
				this.log(`User choose item ${option.id} | ${option.description}`, 'low');
				this.displayedInTheField = option.description;
				this.currentActiveOption = index;
				this.closeOptionsList(option.id);
			},

			closeWithoutSelection(){
				this.log("Closing the optionList without selection", 'low');
				this.textInField = "";
				this.closeOptionsList();
				this.currentActiveOption = null; /* maybe could be unchanged instead? */
				this.runValidationProcess();
				this.$emit('update:modelValue', null);
			},

			toggleListOpeness(){
				this.log("toggleListOpeness", 'function');
				this.isOptionsListOpen = !this.isOptionsListOpen;
				if(this.isOptionsListOpen){
					this.displayedInTheField = "";
					this.makeNewOptionsList(this.autocomplete);
				}
			},

			emptyAndCloseList(){
				this.optionsList = [];
				this.closeOptionsList();
			},

			userChangedInput(){
				/* Refresh list of options */
				/* User left no caracters => options list is empty */
				if(this.displayedInTheField.length === 0){
					this.emptyAndCloseList();
				}
				/* User type some caracters => options list matching strings */
				if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
					this.makeNewOptionsList(this.autocomplete);
					if(!this.isOptionsListOpen){ this.openOptionsList(); }
				}
				/* Display "no result" instead of nothing */
				this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.optionsList.length < 1);
			},

			makeNewOptionsList(bool){
				this.log("makeNewOptionsList", 'function');
				if(this.fieldname !== "postal-code"){
					this.log("optionsFullList", "req");
					this.log(this.optionsFullList);
				}

				/* In both case, if there is a dataRange, I must first filter the source list */
				if(this.dataRange){
					if(this.fieldname !== "postal-code"){
						this.log(this.optionsFullList);
					}
					let tempList = this.optionsFullList.filter(option => {
						return parseInt(option[this.dataRange.key]) === parseInt(this.dataRange.value);
					});
					this.optionsFullList = tempList;
					if(this.fieldname !== "postal-code"){
						this.log("Filtered optionsFullList", "req");
						this.log(tempList);
					}
				}else{
					this.log("There is no dataRange to work with", "low");
				}

				/* If autocomplete is true, it's a partial list */		
				if(bool){
					let userInput = this.displayedInTheField.toLowerCase();
					this.optionsList = this.optionsFullList.filter(option => option.description.toLowerCase().includes(userInput));
					if(this.fieldname !== "postal-code"){
						this.log(this.optionsList);
					}
				}
				/* If autocomplete is false, it's a full list */
				if(!bool){
					this.optionsList = this.optionsFullList;
					if(this.fieldname !== "postal-code"){
						this.log(this.optionsList);
					}
				}
			},

			openOptionsList(){
				/* User open the option list */
				this.log("Opening option list", 'low');
				/* remove previous options */
				this.optionsList = [];
				/* recreate new options list */
				this.makeNewOptionsList(this.autocomplete);
				/* DOM open the list */
				this.isOptionsListOpen = true;
			},

			closeOptionsList(id){
				/* User leave the option list */
				this.noResult = false;
				this.log(`Closing option list. Id received: ${id}`, 'low');
				this.isOptionsListOpen = false;
				this.runValidationProcess(id);
			},

			isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
				let validOptionsList = [];
				if(this.fieldname !== "postal-code"){
					this.log(this.optionsFullList);
				}
				for(let item in this.optionsFullList){			
					validOptionsList.push(this.optionsFullList[item].description.toLowerCase());
				}
				return validOptionsList.includes(this.displayedInTheField.toLowerCase());
			},

			sendDataToForm(id){
				this.log(`SelectAPI transmitting id ${id} to parent`, 'info');
				this.$emit('update:modelValue', id);
			},

			sendValidityOfTheFieldToTheForm(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},

			whatToDoInCaseFieldIsValidated(id){
				this.sendDataToForm(id);
				this.giveSuccess();
				this.sendValidityOfTheFieldToTheForm(true);
			},

			whatToDoInCaseFieldIsNotValidated(){
				this.giveError();
				this.errorMessage = this.$t('inputsTexts.dropdown.missingData');
				this.sendValidityOfTheFieldToTheForm(false);
			},

			runValidationProcess(id){
				this.log(`runValidationProcess with id: ${id}`, 'function');
				this.removeSuccessOrError();
				this.isThisFieldValid() ? this.whatToDoInCaseFieldIsValidated(id) : this.whatToDoInCaseFieldIsNotValidated();
			},

			apiForDropdownIsSuccessful(data){
				this.log("apiForDropdownIsSuccessful (in SelectApi)", 'function');
				// this.log(data);
				/* Remove empty descriptions */
				this.optionsList = data.filter(item => {
					if(this.dataSource === "countries-allowed"){
						return item.description != '' && item.description != null && item.countrytypereference.code === "ConsumerAddress";
					}else{
						return item.description != '' && item.description != null;
					}	
				});
				this.optionsFullList = this.optionsList; /* untouched list */
				if(this.fieldname !== "postal-code"){
					this.log(this.optionsList);
				}
				this.checkForModelValueAndUpdateContentOfField();
			},

			apiForDropdownHasFailed(error){
				this.log("apiForDropdownHasFailed", 'alert');
				this.log(error);
				if(error.response.status === 503){
					this.setNotLoading();
					this.$store.dispatch('setMaintenance', true);
				}else{
					console.log("Cannot get list from API");
					console.error(error);
				}
			},

			checkForModelValueAndUpdateContentOfField(){
				if(this.modelValue){ 
					this.log(`There is a data in the form for the field ${this.fieldname} : ${this.modelValue}.`, 'info');
				/* i must get the option corresponding */
					try{
						let obj = this.optionsList.filter(item => {
							if(this.fieldname !== "postal-code"){
								this.log(item);
							}
							return item.id === parseInt(this.modelValue);
						});
						let selectedOption = obj[0];

						this.displayedInTheField = selectedOption.description;
						this.runValidationProcess(selectedOption.id);

						this.$emit('update:modelValue', parseInt(this.modelValue));
					}catch(e){
						console.log("-----------------------------------------");
						console.log(e);
						console.log("-----------------------------------------");
					}
				}
			},


			requestNewListFromAPI(){
				this.log("requestNewListFromAPI (from SelectApi)", 'function');
				let leadId = this.$store.getters.getLeadId;
				let url = this.isLeadRequested ? `/dropdown/${leadId}/${this.dataSource}` : `/dropdown/${this.dataSource}`;
				this.optionsList = [{id:0, description: "…"}];
				this.setLoading();
				this.axios.get(url) 
				.then(resp => {
					this.apiForDropdownIsSuccessful(resp.data);
				})
				.catch(error => {
					this.apiForDropdownHasFailed(error);
				})
				.then(()=>{
					this.log(`List from API are put in place in ${this.fieldname}.`, 'low');
				});
				this.setNotLoading();
				
			},
		},
		mounted(){
			this.requestNewListFromAPI();
		},
		watch: {
			/* If locale change, must reload the list to take lang into account */
			'$store.getters.getLocale': function(newLocale, oldLocale){
				this.log(`watched locale changed: ${oldLocale} => ${newLocale}`, 'low');
				this.requestNewListFromAPI();
			},

			dataRange(){
				this.log("watched dataRange changed", 'low');
				// If filtering key value change, I must reload all the data, as I already filtered the list to expurge some value.
				this.requestNewListFromAPI();
			},


		},
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
