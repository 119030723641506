let clientName = "Wowi deals";
let productName = "Mocka";
let sloganValue = "35";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const nl = {

	/* Concerning the printing coupons pages */
	printCoupons: {
		page0:{
			title: "Hoe wil je je coupons ontvangen?",
			explanation: "Je kan ze direct afdrukken of een e-mail ontvangen met een link waarmee je ze later kunt afdrukken. Wat wordt het?",
			optionDirectPrint: "Direct afdrukken",
			optionMailPrint: "Een e-mail ontvangen",
		}, 
		page1:{
			title: "Afdrukken van je kortingsbon(nen)",
			intro: `Enkele aanbevelingen om ervoor te zorgen dat alles goed verloopt:`,
			attention: `Je kan je coupons <b>slechts één keer</b> afdrukken. Dus, voordat je afdrukt, <b>bereid je toestellen voor!</b>.`,
			beforePrint: `Voordat je afdrukt...`,
			step1: `Controleer of je printer goed <b>verbonden</b> is met je toestel (pc of mobiel).`,
			step2: `Controleer of je printer <b>aan staat en papier bevat</b>.`,
			step3: `Als alles in orde is, klik dan op de knop "<b>afdrukken</b>" om je eCoupons af te drukken`,
			duringPrint: `Wanneer je afdrukt...`,
			attention2: `Kies de juiste <b>afdrukinstellingen</b>.`,
			warnScale: `Controleer de preview van je eCoupons. Zijn ze volledig zichtbaar? Zo niet, controleer dan in de afdrukopties of de instelling <b>&ldquo;Schaal&rdquo;</b> (<i>"Scale"</i>) van je printer is ingesteld op <b>&ldquo;Passend maken op afdrukbaar gebied&rdquo;</b> (<i>"Fit to printable area"</i>).`,
			badScale: `Als de instelling &ldquo;Schaal&rdquo; ("Scale") niet correct is ingesteld, kunnen je coupons mogelijk niet correct worden afgedrukt en zijn ze <b>onbruikbaar</b>.`,
			waited: `Je zou de eCoupons volledig moeten zien in de preview.`,
			goodScale: `Zorg ervoor dat de schaal is ingesteld op <b>&ldquo;Passend maken op afdrukbaar gebied&rdquo;</b> of een gelijkwaardige instelling.`,
			lastButNotLeast: `Als alle bovenstaande punten in orde zijn, hoeft je alleen nog maar de <b>algemene voorwaarden</b> te aanvaarden om je eCoupons te kunnen afdrukken:`,
			conditions: "Ik aanvaard de algemene voorwaarden die van toepassing zijn op deze kortingsbon(nen).",
			moreInfo: "Meer details",
			button: "Mijn kortingsbon afdrukken",
		}
	},


	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Deze actie is nog niet beschikbaar.",
		actionEnded: "Deze actie is helaas afgelopen.",
		maxReached: "Maximum aantal inzendingen bereikt",
		wrongCode: "Verkeerde code",
		wrongCode2: "Verkeerde deelname link",
		alreadySend: "Deelname reeds verstuurd",
		registration: "Inschrijving",
		register: "Inschrijven",
		confirmReset: "Bevestiging",
		confirmation: "Bevestiging",

		confirmationCoupon: "Bevestiging",
		confirmationMail: "Bevestiging",

		choiceSurvey: "",
		survey: "Enquête",
		summary: "Besteloverzicht",
		maintenance: "Onderhoud",
		form1: "Ontvang uw cashback nu!", 
		form2: "Wat meer informatie...", 
		wait: "Eventjes geduld...", 
		instructions: "Hoe deel te nemen",
		pageError: "Er is een fout opgetreden",

		iframe: "Mijn kortingsbonnen afdrukken",
		modifLoginPass: "Wijzig login en wachtwoord.",
		modifProfile: "Profielgegevens wijzigen",
		modifPrefs: "Je voorkeuren aanpassen",
		profile: "Mijn profiel",
		rememberLogin: "Een inlogherinnering aanvragen",
		resetPassword: "Een nieuw wachtwoord aanvragen",
		changePassword: "Wijzig je wachtwoord",
		confirmCodeMailSent: "Code verzonden!",
		confirmLinkSent: "Link verzonden!",
		warningBeforePrint: "Voor het afdrukken",
		consentAgain: "Wijzigingen aan de algemene voorwaarden",

		confirmReservation: "Reservatie bevestigd",
		summaryCashback: "Mijn cashbacks aanvragen",
		proofOfPurchasePage: "Stuur uw cashbacks", 
		confirmCashback: "Bevestiging 👍",
		confirmPrime: "Bevestiging 👍",

		cannotPrintCoupon: "Kan niet afdrukken",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Kies een manier om te registreren",
		thanksForRegistration: "Bedankt voor uw inschrijving!",
		aboutYou: "Vertel ons meer over u",
		address: "Uw adres",
		aboutProduct: "Over ons product",
		ticketUpload: "Aankoopbewijs",

		infoConnect: "Inlog informatie",
		userInfo: "Uw contactgegevens",
		userPrefs: "Uw voorkeuren",
	},

	/* inputsTexts is a list for every label and helpers that Action API don't give. */
	inputsTexts: {

		listEan: {
			label: "Selecteer de streepjescode uit de lijst of vul het veld in met de streepjescode",
			placeholder: "Selecteer of vul in",
			helper: "De streepjescode bestaat uit 13 cijfers",
		},
		freeEan: {
			label: "Vul het veld in met de streepjescode",
			placeholder: "Voer hier de 13 cijfers in",
			helper: "De streepjescode bestaat uit 13 cijfers",
		},

		dropdown : {
			placeholder: "Kies een antwoord...",
			helper: " ",
			missingData: "Het veld moet een antwoord bevatten",
			noResult: "Geen resultaat",
			errorData: "De gegevens zijn onjuist",
			errorLength: "De gegevens hebben de verkeerde lengte",
		},
		title : {
			label: "Aanspreking",
			placeholder: "Hoe kunnen we u noemen?",
			helper: " ",
		},
		gender: {
			label: "Geslacht",
			placeholder: "Hoe definieer je jezelf?",
			helper: " ",
		},
		firstName: {
			label: "Voornaam",
			placeholder : "Mijn voornaam is...",
			helper: " ",
		},
		lastName: {
			label: "Naam",
			placeholder : "Mijn naam is...",
			helper: " ",
		},
		email : {
			label: "E-mail",
			placeholder: "naam{'@'}voorbeeld.be",
			helper: " "
		},
		emailbis : {
			label: "E-mail opnieuw",
			placeholder: "naam{'@'}voorbeeld.be",
			helper: " "
		},
		password: {
			label: "Wachtwoord",
			placeholder: "",
			helper: " "
		},
		passwordReset: {
			label: "Nieuw wachtwoord",
			placeholder: "********",
			helper: " "
		},
		passwordRegister : {
			label: "Wachtwoord",
			placeholder: "",
			helper: `Het wachtwoord moet tussen de 8 en 20 tekens lang zijn.<br>
			Het moet minstens één cijfer, één hoofdletter en één kleine letter bevatten.<br>
			En minstens één van deze tekens:<br> &#33; &#35; &#36; &#37; &amp; &#42; &#43; &#45; &#47; &#61; &#63; &#94; &#95; &#46; &#123; &#124; &#125; &#126; &#64; &#92; &#91; &#93;.`
		},
		wowiCardFilter: {
			label: "Filter op:",
			placeholder: "",
			helper: " ",
		},
		phone : {
			label: "GSM-nummer",
			placeholder: "Vb: 0477123456",
			helper: " "
		},
		IBAN : {
			label: "Gelieve uw IBAN nummer in te vullen",
			placeholder: "bv: BE00123000456000",
			helper: "Het nummer moet beginnen met de landcode (BE/LU/...)"
		},
		VATNumber: {
			label: "BTW-nummer",
			placeholder: "Vb: BE0123123123",
			helper: "Het nummer moet beginnen met de landcode (BE/LU/...)"
		},
		googleAPI:{
			label: "Gelieve hieronder je volledige adres in te geven (inclusief huisnummer) om dit formulier te kunnen valideren.",
			placeholder: "Zoeken naar...",
		},
		street : {
			label: "Straat",
			labelfacture: "Facturatieadres",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Nummer",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Bus",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Postcode - Gemeente",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Gemeente",
			placeholder: "",
			helper: " "
		},
		cityPrecision: {
			label: "Specificeer a.u.b.",
			placeholder: "",
			helper: " "
		},
		countries: {
			label: "Land",
			placeholder: "Selecteer het land",
			helper: " "
		},
		conditions : {
			part1 : "Ik aanvaard",
			link: "de algemene voorwaarden",
			part3: "."
		},		
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Welk product heeft u aangekocht?",
			placeholder: "Kies een antwoord...",
			helper: " "
		},
		retailer: {
			label: "Waar heeft u het product aangekocht?",
			placeholder: "Kies een antwoord...",
			helper: " "
		},
		freeRetailer: {
			label: `In welke winkel hebt u uw ${productName} producten gekocht?`,
			placeholder: "...",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Gelieve dit veld in te vullen",
			error_wrongValue: "Het antwoord is onjuist",
			error_passwordLength: "Het wachtwoord moet tussen de 8 en 20 tekens lang zijn. ",
			error_passwordLowercase: "Het wachtwoord moet minstens één kleine letter bevatten. ",
			error_passwordUppercase: "Het wachtwoord moet minstens één hoofdletter bevatten. ",
			error_passwordNumber: "Het wachtwoord moet minstens één cijfer bevatten. ",
			error_passwordSpecialCha: "Het wachtwoord moet minstens één speciaal teken bevatten.",
		},
		datePurchase : {
			label: `Wanneer hebt u uw ${productName} producten gekocht?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>PAS OP:</strong> Gebruik de kalender om de datum in te voeren.`,
		},
		uploads: {
			ticket: {
				label: "Klik of sleep je ticket hier."
			},
			dropzone1: {
				label: "Klik of sleep een foto van de streepjescode hier.",
			},
		},
		optin : {
			part1:"Ik wil graag op de hoogte gehouden worden van ",
			linklabel:`${clientName}`,
			link:"https://www.CLIENT.be/nl/",
			part2:"promoties",
			label : "", /* Should not be visible */	
			helper : "",
			complement: `Ik wil op de hoogte blijven van toekomstige acties van ${clientName}.`,
			longtext : {

				part1: `Ja, ik wil informatie ontvangen via e-mail over promoties, nieuws en speciale aanbiedingen van de deelnemende merken. Ik ben ervan op de hoogte dat ik mijn toestemming op elk moment kan beheren of intrekken via het email adres vermeld in onze `,
				linkCaption: `algemene voorwaarden`,
				part2: ` of in elke e-mail. Voor meer details over hoe jouw gegevens worden verwerkt, zie de Privacyverklaring van deelnemende merken.`,	
			}, 
		},
		code: {
			label: "Voer hier de code in die u hebt ontvangen", 
			placeholder: "Voorbeeld: 123456",
			helper: "Het bevat 6 cijfers.",
		}
	},

	wowicard: {
		reimbursed: "terugbetaald",
		textOffersFirstPart: "Nog",
		textOffersLastPart: {
			plural: "aanbiedingen",
			singular: "aanbieding",
		},
		textDateFirstPart: "Nog",
		textDateLastPart: {
			plural: "dagen te gaan",
			singular: "dag te gaan",
		},

		claimed: "Aanbieding reeds ontvangen",
		soldout: "Aanbieding uitgeput",
		expired: "Aanbieding vervallen",
		reserved: "Gereserveerde aanbieding",
		soonOut: "Bijna uitgeput",
		until: "Tot",
	},

	wowiFilterList: {
	default: "Initieel",
		valueAsc: "Waarde van het groeiende aanbod",
		valueDesc: "Waarde van het aanbod neemt af",
		soonClosed: "Binnenkort voltooid",
		random: "Willekeurige volgorde",
		selected: "Geselecteerd",
	},

	testPanel: {
		title: `De "verborgen" pagina's`,
		text: "Klik op de onderstaande knoppen om toegang te krijgen tot de formulierpagina's die geen deel uitmaken van de normale deelnamestroom",
		pages: {
			before: "Voor de start",
			after: "Na het einde",
			maxparticip: "Max. deelname",
			wrongcode: "Verkeerde code",
			alreadysent: "Reeds verzonden",
			maintenance: "In onderhoud",
			loading: "Laden...",
		},
		hide: "Verberg me",
		show: "Laat me zien",
	},


	/* 
	ABOVE: WHAT MUST BE USED NOW 
	BELOW: WHAT WAS USED BEFORE 
	*/



	page404: {
		title: "404",
		text: "Deze pagina bestaat niet.",
		back: "Terug naar het formulier",
	},

	errors: {
		noCouponFile: {
			intro: "Maar geen paniek:",
			help: "We regenereren de coupon en je ontvangt een nieuwe e-mail wanneer deze klaar is.",
		},
		e404: "404 fout",
		error: "Fout",
		wrongUserKey: "Verkeerde identificatiesleutel",
		userDoNotExist: "Gebruiker niet gevonden.",
		noCouponsToOrder: "Er kan geen coupon worden aangevraagd.",
		noSelectedProducts: {
			title: "Oeps!",
			message: "Eerst moet je de coupons selecteren die je wilt afdrukken.",
		},
		accesBlocked: "Uw toegang tot Wowi deals is geblokkeerd.",
		// fields errors
		emptyField: "U moet een antwoord geven",
		dateInvalid: "De datum is niet geldig",
		dateBefore: "De datum mag geen toekomstige datum zijn",
		dateOutOfPeriod: "De aankoop moet worden gedaan binnen de geldige data voor deze actie",
		wrongcnk : "De CNK code is niet correct",
		fieldsMissing : "Kan de deelname niet verzenden omdat één of meer velden niet ingevuld/valide zijn",
		fieldsMissingTitle: "Lege/ongeldige velden",
		unmanaged: "Er is een onvoorziene fout opgetreden.",
		// modale errors, for the user
		fieldMissingValue: "Een of meer velden hebben een onjuiste of lege waarde.",
		fieldMissingValueCsq: "Gelieve het formulier in te vullen.",
		noDescription: "Geen beschrijving beschikbaar.",
		undefinedError: "Ongedefinieerde fout.",
		undefinedErrorPleaseRetry: "Er is een ongedefinieerde fout opgetreden. Probeer opnieuw.",
		maintenanceInProgress: "Onderhoud wordt uitgevoerd.",
		formUnavailable: "Probeer het later nog eens.",
		error500: "Er is een fout met de server opgetreden.",
		oupsy: "Oeps!",
		// Erreurs we don't need to give user too much details on, but a ref! So the user can tell us!
		// KISS Code explanation: take the first letter of each word of the variable
		countryListUnattainable: "Code: CLU",
		noFieldConfigFound: "Code: NFCF",
		participationNotSent: "Code: PNS",
		partOwnerNotCreated: "Code: PONC",
		leadNotFound: "Code: LNF",
		retailerListNotFound: "Code: RLNF",
		productCodesNotFound: "Code: PCNF",
		localizationDataNotFound: "Code: LDNF",
		codeWrongOrUnidentified: "Code: CWOU",
		mailWithCouponsNotSent: "Code: MWCNS",
		printCouponsNotWorking: "Code: PCNW",
	},






	maintenance: {
		title: "Onderhoud",
		line1 : "Deze website is momenteel in onderhoud en kan niet worden geraadpleegd. Probeer het later nog eens.",
		line2 : "Ons team doet er alles aan om de site zo snel mogelijk weer toegankelijk te maken.",
		thanks : "Dank u voor uw begrip.",
	},

	/* Classic Footer */
	footer: {
		genterms: "Algemene voorwaarden",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "",
			label: "Privacyverklaring",
		},
		cookies: {
			link: "",
			label: "Cookieverklaring",
		}
	},

	gdpr: {
		title: "Privacy notice",
		client: `${clientName}`,
	},

	navigation: {
		doSurvey: "Antwoorden op de enquête",
		skipSurvey: "Cashback aanvragen",
		previous: "Vorige pagina",
		next: "Volgende pagina",
		claimCashback: "Mijn Cashback aanvragen",
		signup: "Schrijf je nu in!",
		participate: "Deelnemen!",
		toCustomerWebsite: {
			label: "Naar onze website",
			link: ""
		},
		readMoreOnWebsite: "Lees meer",

		menu: {
			coupons: "E-coupons",
			cashbacks: "Cashbacks",
			primes: "Promocodes",
			myProfil: "Mijn profiel",
			logout: "Uitloggen", 
		}

	},

	breadcrumbSteps: {
		registration: "Inschrijven",
		store: "Doe uw aankopen",
		form: "Vul het formulier in",
		survey: "Vul een optionele vragenlijst in",
		cashback: "Ontvang uw terugbetaling"
	},

	formHeader: {
		bottomText: `* Bij aankoop van Lorem ipsum`,
		macaronText: `terugbetaald!`,
		mainTitle: "Schrijf u in en ontvang uw cashback!"
	},

	buttons: {
		validation: "Bevestig",
		cancel: "Annuleren",
		changePassword: "Bewerken",
		logme: "Inloggen",
		sendLink: "Stuur mij de link",
		login: "Inloggen",
		logout: "Uitloggen", 
		register: "Inschrijven",
		printMyCoupons:"Mijn kortingsbonnen afdrukken",
		emailMyCoupons:"Mijn kortingsbonnen ontvangen",
		emailMyPromocode: "Mijn promocode ontvangen",
		updateLoginInfo:"Bewerken",
		modifyProfile:"Profiel bewerken",	
		updatePrefs: "Voorkeuren wijzigen",
		updateProfile: "Profiel bewerken",
		backToOffers:"Terug naar aanbiedingen",
		backToProfile:"Terug naar profiel",
		myProfil: "Mijn profiel",
		goToOffers: "Aanbiedingen bekijken",

		enjoyCoupons: "Profiteer van eCoupons",
		enjoyCashback: "Profiteer van cashbacks",
		enjoyPromocodes: "Profiteer van promocodes",
		sendCashbacks: "Mijn cashbacks aanvragen",
		reserveCashbacks: "Reserveer cashbacks",
	},


	pageLitteracy: {
		probanner: {
			title1: "Ben je een professional met een btw-nummer?",
			title2: "Dit zal je zeker ook interesseren:",
		},

		cannotPrintCoupon: {
			text: "Helaas ondersteunt wowi deals nog geen afdrukken vanaf je apparaat.",
			text2: "Probeer het afdrukken vanuit een andere browser (Firefox, Safari) of vanaf een PC/MAC.",
		},

		header: {
			spareCoupons: `Kortingsacties`,
			spareCashbacks: `Cashback acties`,
			sparePrimes: `Promocodes`,
		},

		newConsent: {
			consetGenCond: `Let op: de voorwaarden zijn gewijzigd sinds de laatste Wowi deals actie. Accepteer de nieuwe voorwaarden om verder te gaan. U hoeft dit maar één keer te doen.`,
			consetOptin: `U kunt ook uw toestemming geven om berichten te ontvangen van nieuwe deelnemende merken:`,
		},

		notYetRegistered: "Nog niet geregistreerd?",
		noMoreOffers: "Er zijn momenteel geen aanbiedingen meer beschikbaar.",
		infoModifyLimitation: "U kunt slechts één gegeven per keer wijzigen.",
		profileGreeting: "Hallo",
		profileIntro : "U heeft ons de volgende informatie bezorgt. U kunt deze hier wijzigen.",
		profileLogin: "Inloggen", 
		profileEmail: "Email",
		profileTel: "Gsm-nummer",
		profilePassword: "Wachtwoord",
		profileFirstName: "Voornaam",
		profileLastName: "Achternaam",
		profilePostalCode: "Postcode",
		profileCity: "Stad", 
		profileCountry: "Land",
		warningBeforePrint: `Je kunt het afdrukken maar één keer aanvragen. Zorg ervoor dat je apparaat is aangesloten op een printer en dat de printer klaar is om af te drukken.`,
		warningAlternative: "Als je je kortingsbonnen niet meteen kunt afdrukken, ontvang je een link in een e-mail waarmee je ze later kunt afdrukken.",
		
		aboutWowi: `Bespaar meer dan <strong>€ ${sloganValue}</strong>!`,

		wowiLoggedGreeting: `Selecteer de aanbiedingen die je wenst af te drukken:`,
		wowiLoggedActionEnded: `Deze actie is afgelopen. De aanbiedingen zijn niet langer beschikbaar.`,
		wowiIntro: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci quam nostrum suscipit sit sequi, a impedit fugiat quasi accusamus doloremque quis, perferendis recusandae amet, minus. Perspiciatis repellendus veniam fuga atque?`,
		modifProfile: "Wijzig hieronder de waarden die u wilt bijwerken.",
		smsToConfirm: "Je ontvangt een bevestigingscode per sms of e-mail (als je het telefoonnummer wijzigt) om de wijziging te valideren.",
		pastOffers: "Aanbiedingen niet meer beschikbaar",
		printMyCoupon: "Mijn coupons afdrukken",
		sendProfile: "Inschrijving",
		modales : {
			login: "Inloggen",
			code: "Code",
			askCode: "Stuur me een nieuwe code",
			codeError: `De ingevoerde code is niet correct.<br>Probeer het opnieuw.`,
			validationCode: "Validatie code",
			forgotLogin: "Ik ben mijn inloggegevens vergeten.",
			forgotPassword: "Ik ben mijn wachtwoord vergeten.",
			errorLoginPassUnrecognised: `Uw login en/of wachtwoord zijn niet herkend. Probeer het opnieuw.`,
			errorCodeUnrecognised: `De ingevoerde code werd niet herkend.<br>Probeer het opnieuw of vraag een nieuwe code aan.`,
			shouldReceiveSMS: "U zou een tijdelijke code per sms of per e-mail moeten hebben ontvangen. Voer uw code hier in om uw identiteit te bevestigen.",

			reservation: {
				titleModaleBox: "Reservatie",
				titleInsideModale: "Wat is dat?",
				p1: "Een cashback geeft je recht op een (volledig of gedeeltelijke) terugbetaling van de aankoopprijs nadat je je aankoop al hebt voltooid. Aangezien onze cashback acties in voorraad beperkt zijn, heb je 7 dagen de kans om jouw product te reserveren. Dat geeft jou de tijd om naar de winkel te gaan, het product te kopen en je aanvraag tot terugbetaling binnen de periode van 7 dagen in te dienen. Zo ben je zeker van jouw cashback op je favoriete product.",
				p2: "Indien dit niet binnen de 7 dagen zou lukken, komt deze aanbieding terug beschikbaar voor iedereen.",
				button: "Ik snap het!",
			},
			emptyOrder: {
				title: "Annulatie",
				text: "De geselecteerde cashback(s) konden niet reserveerd of gevraagd worden, omdat ze al reserveerd of gevraagd zijn! 😃",
				button: "Ok"
			},
			participation: {
				titleModaleBox: "Deelname",
				titleInsideModale: "Hoe gaat het in zijn werk?",
				p1: `Om jouw cashbacks te ontvangen, dien je, via het deelnameformulier, het volgende te doen:`,
				li1: `Geef ons je IBAN-bankrekeningnummer door als dat nog niet in jouw profiel staat.`,
				li2: `Selecteer de streepjescode uit de lijst of vul het veld in met de streepjescode, afhankelijk van de producten waarvoor je een terugbetaling aanvraagt.`,
				li3: `Stuur ons een foto of scan van jouw kassaticket.`,
				p2: `Zorg ervoor dat je op het kassaticket de aankoopdatum en de producten waarvoor je een terugbetaling aanvraagt, omcirkelt.`,
				p3: `Daarna is het heel eenvoudig: klik op "Jouw cashbacks aanvragen", vul het formulier in en dien jouw aanvraag in.`,

				p4: `Als je meerdere kassatickets hebt, moet je ze één voor één verzenden: selecteer de producten op één kassaticket, neem deel, selecteer vervolgens de producten op het volgende kassaticket en neem opnieuw deel, enz.`,
				p5: `Je kan op elk gewenst moment tijdens de actieperiode een cashback aanvragen.`,
				button: "Ik snap het!",
			},
		},
		resetPassword: "Voer het e-mailadres in waarmee u zich hebt geregistreerd. Je ontvangt een link waarmee je je wachtwoord kunt wijzigen.",
		changePassword: "Voer hier het nieuwe wachtwoord in dat je wilt gebruiken. Je ontvangt vervolgens een bevestigingscode per sms om de wijziging te bevestigen.",
		confirmLinkSent: "Indien het emailadres bij ons gekend is, ontvang je een e-mail waarmee je je wachtwoord kan wijzigen.",
		confirmReset: "Uw wachtwoord is gewijzigd. U kunt ze nu gebruiken om in te loggen.",
		endOfList: "Einde van de lijst.",
		mustBeLogged: "U moet ingelogd zijn om van deze aanbiedingen te kunnen profiteren.",

		confirmReservation: {
			title: `Je hebt de volgende producten succesvol gereserveerd:`,
			p1 : "De reservatie geldt voor een periode van 7 dagen, waarin wij jou de mogelijkheid bieden om gebruik te maken van de gereserveerde cashback(s).",
			p2 : "Na deze periode van 7 dagen kunnen we niet langer garanderen dat je nog kan genieten van deze cashbackactie(s). Wacht dus niet te lang! 🙂",
			btn_back: "Terug naar de aanbiedingenlijst",
		},

		summaryCashback: {
			p1: `Je kunt <b>slechts één kassaticket tegelijk indienen</b>.`,
			p2: `Als al jouw aankopen op dezelfde kassabon staan, selecteer dan alle aanbiedingen die van toepassing zijn.`,
			p3: `Als je jouw aankopen in verschillende winkels of op verschillende tijdstippen hebt gedaan en meerdere kassabonnen hebt, selecteer dan de aanbiedingen voor alle producten die op één kassabon staan. Vraag deze aanbiedingen aan en doe hetzelfde voor de andere kassabonnen.`,
			title: `Je hebt de volgende producten geselecteerd voor dit kassaticket:`,
			btnModify: "Mijn selectie wijzigen",
			btnValidate: "Mijn selectie bevestigen",
		},

		proofOfPurchase: {
			title: "Controleer of uw juiste IBAN-bankrekeningnummer in het onderstaande veld staat vermeld.",
			title2: "Selecteer uit de lijst of vul het veld in met de streepjescodes van de producten waarvoor u een terugbetaling aanvraagt.",
			title3: "Uw aankoopbewijs",
			p1: "Omcirkel op uw aankoopbewijs de producten en de datum van aankoop. Maak vervolgens een foto of scan van uw aankoopbewijs en stuur het in het onderstaande veld in.",
			btn_send: "Verzenden",
		},

		confirmCashback: {
			p1: "We danken je voor je deelname.",
			p2: "Als je aanvraag voldoet aan onze algemene voorwaarden, ontvang je de terugbetaling voor de geselecteerde producten binnen de 2 tot 4 weken.",
			p3: "We raden je aan om jouw aankoopbewijzen zorgvuldig te bewaren totdat je jouw terugbetaling hebt ontvangen.",
			p4: "Intussen kan je ook",
			linktocoupons: "onze eCoupon-aanbiedingen",
			linktocashbacks: "onze Cashback-aanbiedingen",
			linktoprimes: "onze promo code",
			p4final: "raadplegen",
			p5: "Met vriendelijke groeten,",
			p6: "Het Wowi deals team",
			btn_again: "Meer cashbacks aanvragen",
			btn_back: "Terug naar de aanbiedingenlijst",
		},

		confirmPrime: {
			p1: "We danken je voor je deelname.",
			p2: "We hebben je voor elke aanbieding een unieke code gemaild.",
			warn: `Opgelet, indien je binnen een paar minuten geen e-mail hebt ontvangen, controleer dan je <b>spam&nbsp;/&nbsp;ongewenste mails</b>.`,
			p3: "Ga naar het klantenportaal en gebruik deze unieke code(s) om jouw promotie te valideren.",
			p4: "Je kan eveneens genieten van ",
			or: "en",
			linktocoupons: "onze eCoupon-",
			linktocashbacks: "Cashback-aanbiedingen",
			linktoprimes: "onze promo code",
			p5: "Met vriendelijke groeten,",
			p6: "Het Wowi deals team",
			btn_again: "Meer promocodes aanvragen",
			btn_back: "Terug naar de aanbiedingenlijst",
		},

		wowiCashbacks: {
			register: "Schrijf je in",
			registerFor: ` en geniet van aanbiedingen op tal van producten!`,
			action: `Selecteer de cashbacks die je wil <strong>reserveren</strong> of <strong>profiteer er meteen van</strong>`,
			infoReserve: `<b>Reserveer</b> je deelname aan een cashback voor een periode van 7 dagen om er zeker van te zijn dat je ervan kan profiteren.`,
			infoAsk: `<b>Vraag</b> je cashback aan zodra je jouw aankopen hebt gedaan. Zorg ervoor dat je jouw kassaticket bij de hand hebt.`,
			moreInfo: "Meer info",
		},

		pageError: {
			intro: "Er is een fout opgetreden waardoor het formulier niet werkt.",
			solution1 : "Probeer het formulier opnieuw te laden of probeer het later opnieuw..",
			solution2 : `Als de fout blijft bestaan, neem dan contact op met Wowi deals via <a href="mailto:infolijn{'@'}wowideals.be">infolijn{'@'}wowideals.be</a>.`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Informatie pagina",
				register: "Als u van plan bent op een later tijdstip deel te nemen, registreer u dan nu, zodat u een herinnering ontvangt.",
				store: {
					line1a: "Haast u naar de winkel tussen",
					line1b: "en",
					line2: "Koop een MARQUEMACHIN product(en).",
					line3: "Houd uw kassaticket of factuur goed bij.",
				},
				form: {
					line1: "Vul uw gegevens in.",
					line2: "Neem het aankoopbewijs van uw MARQUEMACHIN product(en) erbij en omcirkel de datum, het aangekocht product en de prijs.",
					line3: "Scan of neem een foto van uw volledig aankoopbewijs.",
					line4: "Upload deze foto of scan.",
				},
				survey: "Doe een optionele enquête en ontvang een kortingsbon.",
				cashback: "Eens we uw deelname goedgekeurd hebben, ontvangt u uw terugbetaling binnen de 6 weken.",
			}
		},
		maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",	
		wrongCode: {
			text: `De opgegeven code is verkeerd. U moet de link gebruiken die u door ${clientName} wordt toegestuurd.`,
			textregistration: "Maar misschien wil je je inschrijven voor de actie?",
			errorWithReparticipation: `Er is een fout opgetreden met uw deelname link.<br>Neem contact op met onze technische teams door een e-mail te sturen naar <a href="mailto:infolijn{'@'}wowideals.be">infolijn{'@'}wowideals.be</a> met onderstaande informatie:`
			/* ! Missing translation */
		},
		alreadySend: "U heeft uw deelname al verzonden",
		registration : {
			byRegistering: "Door me in te schrijven voor deze actie, ga ik akkoord met de",
			privacyNotice: "Privacy notice",
			claimMail : "Inschrijven via e-mail",
			claimWhatsapp: "Inschrijven via Whatsapp",
			claimSms: "Inschrijven via SMS",
			or: "Of",
			whatsappTitle: "Klik op de Whatsapp knop",
			whatsappInfo: {
				part1: `Een venster wordt geopend en u wordt gevraagd om het woord`,
				part2: `van uit uw Whatsapp te verzenden.`
			},
		},
		confirmRegistration: {
			smsLine1 :"U zal spoedig een SMS ontvangen met alle details.",
			smsLine2: "Ga eens kijken!",
			whatsappLine1: "Controleer uw telefoon.",
			whatsappLine2: "Alle instructies zijn verstuurd via Whatsapp",
			mailLine1: "U zal spoedig een e-mail ontvangen met alle details.",
			mailLine2: "Ga eens kijken!",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Neem deel aan onze enquête en krijg een e-coupon van €1 voor uw volgende aankoop",
		},
		survey: {
			title: "Bedankt voor het beantwoorden van deze vragen",
			about: "Enquête",
		},
		confirmation: {
			title: "Bevestiging",
			textes: {
				p1: `Bedankt voor je deelname aan de Wowi deals georganiseerd door HighCo DATA.`,
				p2: `We hebben je een e-mail gestuurd met daarin de link om je kortingsbonnen af te drukken.`,
				warn: `Opgelet, indien je binnen een paar minuten geen e-mail hebt ontvangen, controleer dan je <b>spam&nbsp;/&nbsp;ongewenste mails</b>.`,
				p3: `Geniet ervan en aarzel niet de voordelen van Wowi deals te delen met je vrienden en familie!`,
				signature: `Het ${clientName} team`
			},
		},
		confirmationCoupon: {
			title: "Zijn je coupons correct afgedrukt?",
			btn_yes: "Ja",
			btn_no: "Nee",
		},
		goodCouponPrinting: {
			title: "Bevestiging",
			textes: {
				p1: `Bedankt voor het afdrukken van je eCoupons.`,
				p2: "Ga snel naar je dichtsbijzijnde winkel om deze in te ruilen.",
				p3: "",
				p4: `Het <em>${clientName}-team</em>`
			},
			btn_back: "Terug naar de aanbiedingenlijst",
		},
		badCouponPrinting: {
			title: "Je coupons worden niet goed afgedrukt",
			textes: {
				p1: `Onze excuses voor dit ongemak.`,
				p2: `Onze teams zijn op de hoogte gebracht.`,
				p3: `Je ontvangt binnenkort <b>een e-mail</b> met een nieuwe link om een <b>nieuwe afdruk</b> te maken.`,
				p4: `Het <em>${clientName}-team</em>`
			},
			btn_back: "Terug naar de aanbiedingenlijst",
		},


		confirmationMail: {
			title: "De e-mail is verstuurd",
			textes: {
				p1: `De e-mail is verstuurd. Je zou het binnenkort moeten ontvangen. Als je het niet snel ontvangt, controleer dan of het niet in je spammap terecht is gekomen.`, 
			}
		},
		actionEnded: {
			text: "Bezoek onze website regelmatig om op de hoogte te blijven van toekomstige acties.",
			title2: "Had u gereserveerde cashbacks?",
			text2: "Het is misschien nog niet te laat: zolang de reservering van 7 dagen nog niet is verstreken, kunt u nog steeds van deze cashbacks profiteren.",
			btn_log: "Inloggen",
			btn_offers: "De resterende cashbacks bekijken",
		},
		beforeStart: {
			text: "U krijgt toegang vanaf ",
			shouldBeginSoon: "De actie moet spoedig beginnen. Kom later nog eens terug. "
		},
	},









	inputLabel: {
		defaultListHelper: "Kies er één uit de lijst",
		mandatoryFields: "Verplichte velden",
		noResult: "Geen resultaat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: `Wat is de CNK code van het gekochte ${clientName} product?`,
			placeholder: "0000-000",
			helper: "Het format is: XXXX-XXX",
			error: "De CNK code is niet correct",
		},



      //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation

/*
      typePassword : "Gelieve je wachtwoord in te vullen",
      helpPassword : "Je wachtwoord moet X, Y en Z bevatten",
      typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
      */

		upload: {
			state: "status",
			uploadYourFile: "Upload bestand",
			clickOrDrop: "Klik of sleep je ticket hier.",
			rules: "Afbeelding moet een bestand zijn van het bestandstype JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Verwijder",
			waiting: "Wachten op upload...",
			uploaded: "Geüpload!",
			error: "Mislukt",
			uploadsDone: "Upload succesvol afgerond",
			onlyImages: "Enkel afbeeldingen worden aanvaard.",
			maxSize: "Maximale grootte van 20 MB",
			uploadFile: "Upload bestand",
			generalError: "Er ging iets mis met je upload, gelieve later opnieuw te proberen"
		}
	},
}
