<template>
	<div class="aCustomInput field">
		<label :for="translationSource"><span v-html="customLabel"></span>&nbsp;<span v-if="isRequired" class="req">*</span></label>
		<div class="inputcontainer">
			<input
			:id="translationSource"
			:type="currentInputType"
			:name="name"
			:autocomplete="autocompleteName"
			v-model="modelValueComputed"
			:placeholder="$t(`inputsTexts.${translationSource}.placeholder`)"
			:disabled="isDisabled"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			autocapitalize="off" 
			spellcheck="false" 
			autocorrect="off"
			@paste.prevent="onPaste"
			>
			<div class="eye" v-if="inputType === 'password' && isTypingInProgress" :class="{'clicked': currentInputType==='text'}" @touchstart.passive="showPassword(true)" @mousedown="showPassword(true)" @touchend="showPassword(false)" @mouseup="showPassword(false)">
				<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 23.2 23.2" style="enable-background:new 0 0 23.2 23.2;" xml:space="preserve">
				<path class="black" d="M0.6,12.2c0,0,2.8-5.7,10.6-5.7c6.6,0,11.1,5.4,11.1,5.4s-4,5.1-11,5.2C4.1,17.1,0.6,12.2,0.6,12.2z"/>
				<path class="white" d="M2.7,12.3c0,0,2.3-4.1,8.5-4.1c5.3,0,8.8,4,8.8,4s-3.3,3.6-8.9,3.6C5.4,15.8,2.7,12.3,2.7,12.3z"/>
				<circle class="black" cx="11.2" cy="11.1" r="4.1"/>
				<circle class="white" cx="11.2" cy="11.1" r="2.9"/>
				<circle class="black" cx="11.2" cy="11.1" r="1.9"/>
				<circle class="white" cx="10.2" cy="10.1" r="0.8"/>
			</svg>
		</div>
	</div>
	<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
	<div class="helper"><span v-html="$t(`inputsTexts.${translationSource}.helper`)"></span></div>
</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import dayjs from "dayjs";
	import config from "../config";
	import CustomLog from "../mixins/CustomLog";
	var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
	dayjs.extend(isSameOrBefore)
	export default {
		name: "freeInput",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

		mixins: [
			CustomLog,
			FormFields,
			],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			isEmptyValueTolerated : {
				type: Boolean,
				required: false,
			default: false,
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			addData: {
				type: Object,
				required: false
			},
			displayErrors: {
				type: Boolean,
				required: false,
			default: true
			},
			acceptedValues: {
				type: Array, 
				required: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
		},

		data(){
			return {
				errorMessage : "",
				config: config,
				eyePressed: false,
				allowCopyPaste: true,
			}
		},

		computed: {

			isTypingInProgress(){
				return !!this.modelValueComputed;
			},

			currentInputType(){
				/* Eye only works for password */
				if (this.inputType === "password"){
					return this.eyePressed?'text':'password';
				}else{
					return this.inputType;
				}
			},

			customLabel(){
				let label = this.$t(`inputsTexts.${this.translationSource}.label`);
				return this.placeholder ? this.placeholder : label;
			},

			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {

			onPaste(evt){
				this.log("A copy-paste operation is happening…", 'info');
				let text = (evt.clipboardData || window.clipboardData).getData("text");
				if(this.allowCopyPaste){
					this.modelValueComputed = text;
					this.log(`Element "${text}" pasted`, 'low');
				}else{
					this.log(`Element "${text}" cannot be pasted`, 'low');
				}
			},

			showPassword(bool){
				console.log("mousedown detected");
				this.eyePressed = bool;
			},

			validateMyself() {
				this.setLoading();
				this.removeSuccessOrError();

				console.group("FreeInput Validation " + this.fieldname);
				console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);

	  			/* CUSTOM VALIDATION */
	  			/* Acceptables values are given by a prop : acceptedValues */

				if (this.acceptedValues.length > 0){
					this.thereIsAListOfAcceptedValues();
				}else{
					this.thereIsNoListOfAcceptedValues();
				}
				console.groupEnd();
				this.setNotLoading();
			},

			thereIsAListOfAcceptedValues(){
				this.log("thereIsAListOfAcceptedValues", 'function');
				if(this.acceptedValues.includes(this.modelValueComputed)){
					console.log("Value accepted");
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}else{
					console.log("Value rejected");
					this.giveError();
					this.errorMessage = this.$t('inputsTexts.freeInput.error_wrongValue');
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				}
			},

			validationForPasswordField(password){
				this.log(`validationForPasswordField() with password value = ${password}`, 'function');

				let valid = true;
				this.errorMessage = '';

				if(this.displayErrors /* if prop is set to true */){

				/* Password must be between 8 and 20 caracter long */
				if (password.length < 8 || password.length > 20){
					this.log("Password is too short or too long", 'warning');
					this.errorMessage += this.$t('inputsTexts.freeInput.error_passwordLength');
					valid = false;
				}else{
					this.log("Password has the right length", 'low');
				}
				/* Password must at least have one lowercase */
				if( !/[a-z]/.test(password) ){
					this.log("Password contains no lowercase", "alert");
					this.errorMessage += this.$t('inputsTexts.freeInput.error_passwordLowercase');
					valid = false;
				}else{
					this.log("Password has at least one lowercase", 'low');
				}
				/* Password must at least have one uppercase */
				if( !/[A-Z]/.test(password) ){
					this.log("Password contains no uppercase", "alert");
					this.errorMessage += this.$t('inputsTexts.freeInput.error_passwordUppercase');
					valid = false;
				}else{
					this.log("Password has at least one uppercase", 'low');
				}
				/* Password must at least have one number */
				if( !/[0-9]/.test(password) ){
					this.log("Password contains no number", "alert");
					this.errorMessage += this.$t('inputsTexts.freeInput.error_passwordNumber');
					valid = false;
				}else{
					this.log("Password has at least one number", 'low');
				}
				/* Password must at least have one special character from a list */
				/* # ! $ % & * + - / = ?  ^ _  . { | } ~ @ \ [ ] . */
				if(!/[#!$%&*+\-=?^_.{|}~@[\]/]+/.test(password)){
					this.log("Password contains no special char");
					this.errorMessage += this.$t('inputsTexts.freeInput.error_passwordSpecialCha');
					valid = false;
				}else{
					this.log("Password miss a special char");
				}
				} /* END if prop displayError is true */


				/* SPECIAL CASE : If field is empty */
				/* If valid is still true */
				if(valid){
					if(password?.length > 0){
						this.giveSuccess();
						this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
					}else{
					/* Field is empty, it is not valid, but does not display success style */
						this.removeSuccessOrError();
						this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
					}
				}else{
					if(password?.length > 0){
						this.giveError();
					}else{
						this.removeSuccessOrError();
					}				
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				}
			},

			validationForLogin(){
				this.giveSuccess();
				this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
			},

			thereIsNoListOfAcceptedValues(){
				this.log("There is no list of specific accepted values => custom validation", 'low');
				if(this.inputType === 'password'){
					this.validationForPasswordField(this.modelValueComputed);
				} else if(this.fieldname === 'login'){
					this.validationForLogin();
				}else if(!!this.modelValueComputed && this.modelValueComputed.length > 0){          
					this.log("There is something in the field => success");
					this.giveSuccess();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
				}else{

					if(this.isEmptyValueTolerated){
						this.log("There is nothing in the field => ok, it's tolerated", 'low');
						this.removeSuccessOrError();
					}else{
						this.log("There is nothing in the field => error", 'low');
						this.giveError();
						this.errorMessage = this.$t('inputsTexts.freeInput.error_empty');
					}

		  			/* …but still, the field cannot be considered valid */
					this.setNotLoading();
					this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
				}
			},


		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
	// run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		},

		mounted(){
			this.setNotLoading();
			if(!!this.addData && Object.keys(this.addData).length !== 0){
				this.log(`Prop "addData" of free-input "${this.fieldname}" non empty.`, "low");
				Object.keys(this.addData).forEach(key => {
					this.log(`${key}: ${this.addData[key]}`, 'low');
					this[key] = this.addData[key];
				});
			}
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
		},

	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
	.inputcontainer {
		position: relative;
	}
	.eye {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		right: 5px;
		width:2.5em;
		height:2.5em;
		cursor: pointer;
		opacity: 0.6;
	}
	.eye.clicked {
		opacity: 1;
	}
	path,
	circle {
		&.black {
			fill: #000;
		}
		&.white {
			fill: #fff;
		}
	}
</style>
